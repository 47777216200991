import APIRequest from "APIRequest";
import { addNotice } from "Notices";

function* middleware_users_getModifiedUserDetails(ctx) {
	yield ctx.userToBeModified
		? new APIRequest({ endpoint: `users/${ctx.userToBeModified.username}` })
				.then(response => (ctx.userInfo = response))
				.catch(err => {
					console.error(`/users/:username || getAccountDetails middleware failed`, err);
					addNotice({
						id: "getAccountDetails",
						type: "Error",
						data: {
							header: "getAccountDetails middleware failed",
							msg: err
						},
						duration: 5000
					});
					ctx.redirect("/");
				})
		: ctx.redirect("/");
}

export { middleware_users_getModifiedUserDetails };
